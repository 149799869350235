.App {
    width: 80%;
    margin: 0 auto;
}

pre[class*=language-] {
    background: white !important;
    font-size: 0.8em;
    overflow-y: hidden;
}

.demoAddressForm {
  max-width: 720px;
  margin-top: 30px;
}

.demoAddressForm .commonForm label {
  margin-top: 6px;
}

.demoAddressForm label.required:after {
  color: red;
  content:  " *";
}

.demoAddressForm small {
  color: gray;
}

.demoAddressForm .error {
  color: red;
  font-size: 80%;
}

.demoAddressForm .formStatus {
  margin-top: 10px;
}

.cimTechAutocomplete-autocompleteItems {
  background-color: #fff;
  position: absolute;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial,sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  width: 100%; }

.cimTechAutocomplete-iconMarker {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-position: -1px -161px;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px; }

.cimTechAutocomplete-autocompleteItem {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 11px;
  color: #999; }

.cimTechAutocomplete-autocompleteItems .cimTechAutocomplete-autocompleteItemSelected .cimTechAutocomplete-iconMarker {
  background-position: -18px -161px; }

.cimTechAutocomplete-autocompleteItems .cimTechAutocomplete-autocompleteItemSelected {
  background-color: #ebf2fe; }

.cimTechAutocomplete-autocompleteItem:hover {
  background-color: #fafafa; }

.cimTechAutocomplete-autocompleteItemPrimaryText {
  color: black; }

.cimTechAutocomplete-autocompleteItemSecondaryText {
  margin-left: 5px; }

.cimTechAutocomplete-autocompletePoweredWithGoogle {
  padding: 1px 1px 1px 0;
  height: 18px;
  box-sizing: border-box;
  text-align: right;
  display: block;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 120px 14px; }

/*== This comes from https://codepen.io/pure-css/pen/bddggP ==*/

.tool {
  cursor: help;
  position: relative;
}

/*== common styles for both parts of tool tip ==*/
.tool::before,
.tool::after {
  left: 50%;
  opacity: 0;
  position: absolute;
  z-index: -100;
}

.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 100;
}


/*== pointer tip ==*/
.tool::before {
  border-style: solid;
  border-width: 1em 0.75em 0 0.75em;
  border-color: #3E474F transparent transparent transparent;
  bottom: 100%;
  content: "";
  margin-left: -0.5em;
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
  transform:  scale(.6) translateY(-90%);
}

.tool:hover::before,
.tool:focus::before {
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
}


/*== speech bubble ==*/
.tool::after {
  background: #3E474F;
  border-radius: .25em;
  bottom: 180%;
  color: #EDEFF0;
  content: attr(data-tip);
  margin-left: -8.75em;
  padding: 1em;
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
  transform:  scale(.6) translateY(50%);
  width: 17.5em;
}

.tool:hover::after,
.tool:focus::after  {
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
}

@media (max-width: 760px) {
  .tool::after {
        font-size: .75em;
        margin-left: -5em;
        width: 10em;
  }
}

