.cimTechAutocomplete- {

    &autocompleteItems {
        background-color: #fff;
        position: absolute;
        z-index: 1000;
        border-radius: 2px;
        border-top: 1px solid #d9d9d9;
        font-family: Arial,sans-serif;
        box-shadow: 0 2px 6px rgba(0,0,0,0.3);
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
    }

    &iconMarker {
        width: 15px;
        height: 20px;
        margin-right: 7px;
        margin-top: 6px;
        display: inline-block;
        vertical-align: top;
        background-position: -1px -161px;
        background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
        background-size: 34px;
    }

    &autocompleteItem {
        cursor: default;
        padding: 0 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 30px;
        text-align: left;
        border-top: 1px solid #e6e6e6;
        font-size: 11px;
        color: #999;
    }

    &autocompleteItems &autocompleteItemSelected &iconMarker {
        background-position: -18px -161px;
    }

    &autocompleteItems &autocompleteItemSelected {
        background-color: #ebf2fe;
    }

    &autocompleteItem:hover {
        background-color: #fafafa;
    }

    &autocompleteItemPrimaryText {
        color: black;
    }

    &autocompleteItemSecondaryText {
        margin-left: 5px;
    }

    &autocompletePoweredWithGoogle {
        padding: 1px 1px 1px 0;
        height: 18px;
        box-sizing: border-box;
        text-align: right;
        display: block;
        background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
        background-position: right;
        background-repeat: no-repeat;
        background-size: 120px 14px;
    }
}
