.demoAddressForm {
  max-width: 720px;
  margin-top: 30px;
}

.demoAddressForm .commonForm label {
  margin-top: 6px;
}

.demoAddressForm label.required:after {
  color: red;
  content:  "\20*";
}

.demoAddressForm small {
  color: gray;
}

.demoAddressForm .error {
  color: red;
  font-size: 80%;
}

.demoAddressForm .formStatus {
  margin-top: 10px;
}
