.App {
    width: 80%;
    margin: 0 auto;
}

pre[class*=language-] {
    background: white !important;
    font-size: 0.8em;
    overflow-y: hidden;
}
