/*== This comes from https://codepen.io/pure-css/pen/bddggP ==*/

.tool {
  cursor: help;
  position: relative;
}

/*== common styles for both parts of tool tip ==*/
.tool::before,
.tool::after {
  left: 50%;
  opacity: 0;
  position: absolute;
  z-index: -100;
}

.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 100;
}


/*== pointer tip ==*/
.tool::before {
  border-style: solid;
  border-width: 1em 0.75em 0 0.75em;
  border-color: #3E474F transparent transparent transparent;
  bottom: 100%;
  content: "";
  margin-left: -0.5em;
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
  transform:  scale(.6) translateY(-90%);
}

.tool:hover::before,
.tool:focus::before {
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
}


/*== speech bubble ==*/
.tool::after {
  background: #3E474F;
  border-radius: .25em;
  bottom: 180%;
  color: #EDEFF0;
  content: attr(data-tip);
  margin-left: -8.75em;
  padding: 1em;
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
  transform:  scale(.6) translateY(50%);
  width: 17.5em;
}

.tool:hover::after,
.tool:focus::after  {
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
}

@media (max-width: 760px) {
  .tool::after {
        font-size: .75em;
        margin-left: -5em;
        width: 10em;
  }
}
